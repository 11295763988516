import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Material UI
import PlusIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import { theme } from '../styles/GlobalStyle';
import { useWindowWidth } from '../../hooks/useWindowSize';

import { SectionContainer, GridContainer, ButtonLink, Row } from './components';
import {
  APP_STORE_URL,
  IMAGES,
  PLAY_STORE_URL,
  getImage,
  handleTap,
} from '../../utils';

const Magnify = getImage(IMAGES.MAGNIFY.ref);
const logo = getImage(IMAGES.SOLVE_LOGO_BLACK_TEXT.ref);

const CopyContainer = styled('div')<{
  copyWidth?: string;
  isLeftImage: boolean;
  smallerHeaderWidth?: boolean;
}>`
  @media ${theme.mq.desktopLargeMin} {
    padding: 0 16px;
    margin-left: 48px;
    padding: 0;

    ${({ isLeftImage, smallerHeaderWidth, copyWidth }) =>
      isLeftImage
        ? `width: ${copyWidth || '50%'}`
        : smallerHeaderWidth
        ? 'width: 48%'
        : ''};
  }
  & h1 {
    font-family: 'Majorant-Regular';
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.75rem;
    margin: 12px 0 16px;

    @media ${theme.mq.desktopLargeMin} {
      font-size: 3.25rem;
      line-height: 3.5rem;
      margin: 64px 0 48px;
      max-width: 742px;
    }

    & span {
      @media ${theme.mq.desktopLargeMin} {
        font-size: 2.5rem;
      }

      color: ${theme.blue4};
    }
  }
  & h2 {
    font-size: 1.1rem;
    font-family: 'Majorant-Regular';
    margin-bottom: 16px;
    line-height: 200%;

    @media ${theme.mq.desktopLargeMin} {
      font-size: 1.3rem;
      margin-bottom: 48px;
    }
  }
  & h3 {
    font-family: 'Majorant-Light';
    font-weight: normal;
    border-radius: 10px;
    line-height: 200%;
    margin-bottom: 16px;
    font-size: 1rem;

    @media ${theme.mq.desktopLargeMin} {
      font-size: 1.25rem;
      margin-bottom: 48px;
    }
  }
`;

const Image = styled('img')`
  display: flex;
  z-index: -1;
  margin-top: 24px;
  width: 210px;
  margin: auto;
  @media ${theme.mq.desktopLargeMin} {
    width: 325px;
  }
`;

const CTAContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  gap: 18px;
  @media ${theme.mq.desktopLargeMin} {
    flex-direction: row;
    justify-content: space-between;
    max-width: 60%;
    margin-top: 0;
  }
`;

const AdditionalCopy = styled('p')`
  margin-top: 60px;
  font-family: 'Majorant-Light';
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.47);
  border-radius: 10px;
  font-size: 16px;
  line-height: 200%;
  & span {
    display: block;
    font-size: 18px;
    color: ${theme.blue4};
    font-weight: bold;
  }
`;

const StyledSectionContainer = styled(SectionContainer)<{
  isLeftImage: boolean;
}>`
  width: 100%;
  display: flex;
`;

export const TopOfFold = ({
  alt,
  copy,
  ctas,
  imageLocation,
  smallerHeaderWidth,
  ImageOverride,
  partnerLogo,
  copyWidth,
  hasMobileDownload,
  continueOnWeb,
}: {
  alt: string;
  copy: {
    h1Copy: string | React.ReactNode;
    h2Copy?: string | React.ReactNode;
    pCopy: string | React.ReactNode;
    additionalCopy?: string | React.ReactNode;
  };
  ctas?: {
    bgColor?: string;
    to?: string;
    callback?: () => void;
    cta: string;
    color?: string;
    testid?: string;
  }[];
  ImageOverride?: JSX.Element;
  imageLocation: { desktop: 'left' | 'right'; mobile: 'top' | 'bottom' };
  smallerHeaderWidth?: boolean;
  partnerLogo?: {
    svg: string;
    alt: string;
  };
  copyWidth?: string;
  hasMobileDownload?: boolean;
  continueOnWeb?: () => void;
}) => {
  const { isMobile } = useWindowWidth();
  const PartnerSvg = partnerLogo?.svg;

  const CTAs = (
    <CTAContainer>
      {hasMobileDownload && isMobile && (
        <Box>
          <Box mb={3} display="flex" flexWrap="wrap" gap="8px">
            <Link to={APP_STORE_URL}>
              <img
                style={{ width: '165px' }}
                src={getImage(IMAGES.APP_STORE_ICON.ref)}
                alt="Download on Apple App Store"
              />
            </Link>
            <Link to={PLAY_STORE_URL}>
              <img
                style={{ width: '165px' }}
                src={getImage(IMAGES.PLAY_STORE_ICON.ref)}
                alt="Download on Google Play Store"
              />
            </Link>
          </Box>
          <a
            style={{ cursor: 'pointer', marginTop: '32px' }}
            onClick={continueOnWeb}
          >
            Or continue on web &#8594;
          </a>
        </Box>
      )}
      {(!hasMobileDownload || !isMobile) &&
        ctas?.map(({ bgColor, color, callback, to, cta, testid }) => (
          <ButtonLink
            key={to}
            onClick={callback}
            bgColor={bgColor}
            color={color}
            style={{ minWidth: '263px', height: '48px', fontSize: '16px' }}
            data-testid={testid}
          >
            {cta}
          </ButtonLink>
        ))}
    </CTAContainer>
  );

  const isLeftImage = imageLocation.desktop === 'left';

  return (
    <Box mt={8}>
      <GridContainer>
        <StyledSectionContainer isLeftImage={isLeftImage}>
          {!isMobile && imageLocation.desktop === 'left' && (
            <Box display="flex" flexDirection="column">
              {partnerLogo && (
                <Row style={{ margin: '68px 0 64px', gap: '24px' }}>
                  <img
                    src={PartnerSvg}
                    alt={partnerLogo.alt}
                    style={{ height: '38px', width: 'auto' }}
                  />
                  <PlusIcon />
                  <img
                    src={logo}
                    alt="Solve Finance Logo"
                    style={{ height: '38px' }}
                  />
                </Row>
              )}
              {ImageOverride ? (
                ImageOverride
              ) : (
                <Box display="flex" flexDirection="column" flexGrow="1">
                  <Image src={Magnify} alt={alt} />
                </Box>
              )}
            </Box>
          )}
          <CopyContainer
            copyWidth={copyWidth}
            isLeftImage={isLeftImage}
            smallerHeaderWidth={smallerHeaderWidth}
          >
            {partnerLogo && isMobile && (
              <Row
                style={{
                  position: 'relative',
                  zIndex: 1,
                  gap: '24px',
                  flexWrap: 'wrap',
                  marginBottom: '24px',
                  padding: '24px 12px',
                  border: '1px solid #eee',
                  background: '#fafafa',
                  justifyContent: 'center',
                  marginLeft: '-24px',
                  width: 'calc(100% + 24px)',
                }}
              >
                <img
                  src={PartnerSvg}
                  alt={partnerLogo.alt}
                  style={{ height: '38px', width: 'auto' }}
                />
                <PlusIcon />
                <img
                  src={logo}
                  alt="Solve Finance Logo"
                  style={{ height: '38px' }}
                />
              </Row>
            )}
            {isMobile && imageLocation.mobile === 'top' && (
              <>{ImageOverride ? <div>{ImageOverride}</div> : null} </>
            )}
            <Box position="relative">
              <h1>{copy.h1Copy}</h1>
              {copy.h2Copy && <h2>{copy.h2Copy}</h2>}
              <h3>{copy.pCopy}</h3>
              {isMobile && CTAs}
              {!isMobile && CTAs}
            </Box>
          </CopyContainer>
          {isMobile && imageLocation.mobile === 'bottom' && (
            <>{ImageOverride ? ImageOverride : <Image />}</>
          )}
          {!isMobile && imageLocation.desktop === 'right' && (
            <>
              {ImageOverride ? (
                ImageOverride
              ) : (
                <Image style={{ position: 'absolute', right: 0 }} />
              )}
            </>
          )}
        </StyledSectionContainer>
      </GridContainer>
      {copy.additionalCopy && (
        <Box>
          <AdditionalCopy>{copy.additionalCopy}</AdditionalCopy>
        </Box>
      )}
    </Box>
  );
};
