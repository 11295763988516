import React, { useContext, useEffect, useReducer } from 'react';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

// Services
import { captureException } from '../../analytics/sentry';
import { useAuthContext } from '../auth.provider';
import { useUserContext, useUserDispatch } from '../user/user.provider';
import {
  getUser,
  recordEvent,
  requestUserRecommendations,
  updateCreditReport,
} from '../../services/user.service';
import { initialState, userReducer } from './recommendation.reducer';
import {
  creditAttempt,
  isInitialized,
  recommendationAttempt,
  setLoading,
  setPlan,
} from './recommendation.actions';
import { setUser } from '../user/user.actions';
import { wait } from '../../utils';
import { ROUTE_PATHS } from '../../components/_pages/_utils/router.utils';

const RecommendationContext = React.createContext(null);
const RecommendationDispatchContext = React.createContext(null);

export const RecommendationProvider = ({ children }) => {
  const {
    firebaseUser,
    initialized: authInitialized,
    loading: authLoading,
  } = useAuthContext();
  const {
    user,
    initialized: userInitialized,
    loading: userLoading,
  } = useUserContext();
  const userDispatch = useUserDispatch();
  const [state, dispatch] = useReducer(userReducer, initialState);

  const hasOnboarded = userInitialized && user?.hasBureauAuth;
  const loading = state.loading || userLoading || authLoading;
  const isReady = authInitialized && userInitialized && !loading;
  const hasRecommendations = !!state[user?.goal];

  useEffect(() => {
    if (isReady && hasOnboarded && firebaseUser) {
      if (hasRecommendations) {
        dispatch(setPlan({ plan: state[user?.goal], goal: user?.goal }));
      } else {
        dispatch(setLoading(true));

        const fetchRecommendations = async () => {
          try {
            const currScore = user?.currentCreditInfo?.scoreNow;
            const potentialScore = user?.currentCreditInfo?.scorePotential;
            const scoreUsed = user.usePotentialScore
              ? potentialScore
              : currScore;

            if (!user?.lastSnapshotAt) {
              if (state.creditAttempts > 3) {
                window.onbeforeunload = null;
                window.location.href = ROUTE_PATHS.UNHANDLED_ERROR;
              }

              dispatch(creditAttempt());
              await updateCreditReport({ firebaseUser });
              recordEvent({
                eventName: 'Credit Pulled',
                firebaseUser,
                props: {
                  value: 3,
                },
              });

              const user = await getUser(
                firebaseUser.uid,
                (
                  await FirebaseAuthentication.getIdToken()
                ).token
              );
              userDispatch(setUser(user));
            }

            if (state.recommendationAttempts > 3) {
              window.onbeforeunload = null;
              window.location.href = ROUTE_PATHS.UNHANDLED_ERROR;
            }

            dispatch(recommendationAttempt());
            const plan = await requestUserRecommendations({
              firebaseUser,
              goal: user?.goal,
              scoreUsed,
            });

            dispatch(setPlan({ plan, goal: plan?.goal || user?.goal }));
          } catch (e) {
            await wait(3000);
            captureException(e);
          }

          console.debug('RecommendationProvider - Initialized');
          dispatch(isInitialized());
          dispatch(setLoading(false));
        };

        if (window.location.href !== ROUTE_PATHS.UNHANDLED_ERROR) {
          fetchRecommendations();
        }
      }
    }
  }, [
    hasOnboarded,
    isReady,
    firebaseUser,
    hasRecommendations,
    user?.goal,
    user?.usePotentialScore,
  ]);

  console.debug('RecommendationProvider Context: ', state);

  return (
    <RecommendationContext.Provider value={state}>
      <RecommendationDispatchContext.Provider value={dispatch}>
        {children}
      </RecommendationDispatchContext.Provider>
    </RecommendationContext.Provider>
  );
};

export const useRecommendationContext = () => {
  return useContext(RecommendationContext);
};

export const useRecommendationDispatch = () => {
  return useContext(RecommendationDispatchContext);
};
