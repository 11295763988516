import React, { MouseEventHandler, FunctionComponent, ReactNode } from 'react';
import { DEBT_GROUP } from '../../utils';
import { IPlan } from '../../providers/recommendations/recommendation.actions';

export type RequestUserRecommendations = (
  goal: Goal,
  retries?: number,
  clearCache?: boolean,
  scoreUsed?: number
) => Promise<void>;

export interface UserData {
  // TODO: fix-any-type
  // eslint-disable-next-line
  response: any;
  loading: boolean;
  error: string;
  // TODO: fix-any-type
  // eslint-disable-next-line
  userInfo: any;
  // TODO: fix-any-type
  // eslint-disable-next-line
  recommendations: any;
  requestUserRecommendations: RequestUserRecommendations;
  removeUserById: () => Promise<void>;
  isEligibleForHomeAffordability: boolean;
  // TODO: fix-any-type
  // eslint-disable-next-line
  actions: {
    // TODO: fix-any-type
    // eslint-disable-next-line
    requestUserData: () => Promise<void>;
    requestUserRecommendations: RequestUserRecommendations;
    setUserInfo: (
      arg0: Pick<UserData, 'userInfo'> | Record<string, never>
    ) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setRecommendations: (
      recommendations: Pick<UserData, 'recommendations'>
    ) => void;
    nukeUserData: () => void;
    updateIsGovernmentOrNonProfitEmployer: (
      isGovernmentOrNonProfitEmployer: boolean
    ) => void;
  };
  currentGoal: Goal;
}

/**
 * Compile time type checking
 */
export interface IStylable {
  className?: string;
  display?: string;
}
export interface IDebtItem extends IStylable {
  title: string;
  subtitle: string;
  updated: string;
  amount: CurrencyOrRate;
  interest: CurrencyOrRate;
  originalBalance: number;
  isDeferred?: boolean;
  isFHA?: boolean;
}

export interface IManageDebts extends IStylable {
  debtHash: SortedUserDebts;
  user: any;
  plan: IPlan;
  goToDashboard: () => void;
  selectedGoal: string;
}
export interface GoalProps extends IStylable {
  title: string;
  selected: boolean;
}
export interface IRecommendationData extends IStylable {
  type: string;
  subtitle: string[];
  selected: boolean;
  offer: IBestOffer;
  existingInstruments: ServerLoanType[];
}
export interface IRecommendationCard extends IStylable {
  type: string;
  subtitle: string[];
  offer: IBestOffer;
  goToStrategy: () => void;
  flatten?: unknown;
  isDebtConsolidation: boolean;
}
export interface IRecommendationComponent extends IStylable {
  goToDashboard: MouseEventHandler;
  user: any;
  plan: IPlan;
  goal: GoalDataType;
  navigateToAdditionalInformation: (strategy) => void;
}
export type IconType = {
  BlueStairs: FunctionComponent;
  BlueMoney: FunctionComponent;
  BlueHouse: FunctionComponent;
};

export interface ILabelled extends IStylable {
  amount: CurrencyOrRate;
  secondAmount?: CurrencyOrRate;
  invertColors?: boolean;
  title: string;
  subtitle?: string;
  testid?: string;
  tooltip?: string;
  link?: ReactNode;
  modal?: { title: string; body: JSX.Element; description: string };
  smallerFont?: boolean;
  onClick?: () => void;
}
export interface ISelectGoal extends IStylable {
  className?: string;
  goal: GoalDataType;
  goals: GoalDataType[];
  onChange: (value: string) => void;
  newOptions?: boolean;
}
export interface ISelected extends IStylable {
  selected?: [number, number];
}
export interface IPotentialDifference extends IStylable {
  title: string;
  offer: {
    amount: CurrencyOrRate;
  };
  heading: string;
  isEstimate?: boolean;
  isAverage?: boolean;
}
export interface IPotentialValue extends IStylable {
  heading: string;
  predicted: boolean;
  amount: CurrencyOrRate;
  align: string;
  testid?: string;
  isEstimate?: boolean;
  isAverage?: boolean;
}
export interface DebtType extends IStylable {
  title: string;
  subtitle: string;
  amount: CurrencyOrRate;
  interest: CurrencyOrRate;
  updated: string;
}

export enum DEBT_GOAL {
  MIN_TOTAL = 'MIN_TOTAL',
  MIN_MONTHLY = 'MIN_MONTHLY',
  MAX_HOME = 'MAX_HOME',
  IMPROVE_CREDIT = 'IMPROVE_CREDIT',
  GET_CASH = 'GET_CASH',
}

export type Goal = 'MIN_TOTAL' | 'MIN_MONTHLY' | 'MAX_HOME' | 'GET_CASH' | null;

export type GoalDataType = {
  name: string;
  goal: Goal;
  selected: boolean;
  title: string;
  icon: string;
  savings: {
    title: string;
  };
  recommendations: IRecommendationData[];
};
export type DebtCost = {
  value: number;
  delta: number;
  type: 'currency' | 'percent' | 'number';
};
export interface IDashboardProvider {
  page: {
    sections: {
      savings: string;
      recommendations: string;
      cost: string;
      payments: string;
      goal: string;
    };
  };
  goals: {
    MIN_TOTAL: GoalDataType;
    MIN_MONTHLY: GoalDataType;
    MAX_HOME: GoalDataType;
    GET_CASH: GoalDataType;
  };
}

export type IDebt = {
  id: string;
  ref: string;
  externalId: string;
  borrowerId: string;
  group: DEBT_GROUP;
  lender: string;
  interestRateImputed: number;
  initialBalance?: number;
  principalBalance: number;
  creditLimit?: number;
  term: number;
  termRemaining: number;
  totalExpectedCost: number;
  scheduledMonthlyPayment: number;
  paymentPITI: number;
  paymentPI: number;
  paymentTI: number;
  paymentInterval: string;
  originationDate: string;
  lastPaymentDate: string;
  isDeferred: boolean;
  isFederalLoan: boolean;
  isInCollection: boolean;
  isChargeoff: boolean;
  isFHA?: boolean;
  createdAt?: Date;
};

export type SortedUserDebts = {
  [key: string]: IDebt[];
};

export interface IDashboard extends IStylable {
  goal: GoalDataType;
  goals: GoalDataType[];
  onChange: (value: string) => void;
  selectedStrategyCount: number;
  selectedStrategies: boolean[];
  showBoompayStrategy: boolean;
  showHomeAffordabilityModal: {
    isOpen: boolean;
    copy: string;
    initialPotentialChecked?: boolean;
  };
  setShowingHomeAffordabilityModal: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      copy: string;
      initialPotentialChecked?: boolean;
    }>
  >;
  updateSelection: (match: number) => (value: boolean) => void;
  potentialDebtCost: DebtCost;
  potentialMonthlyPayments: DebtCost;
  sortedRecommendations: IRecommendationData[];
  goToDashboard: () => void;
  sections: {
    savings: string;
    recommendations: string;
    cost: string;
    payments: string;
    goal: string;
  };
  debtCostDelta: number;
  monthlyPaymentsDelta: number;
}
export type CurrencyOrRate = {
  value: number;
  type: string;
  delta: number;
};
export type Offer = {
  type: string;
  amount: CurrencyOrRate;
  isEstimate?: boolean;
};
export interface IResultValue extends IStylable {
  title: string;
  offer: Offer;
  isEstimate?: boolean;
  isAverage?: boolean;
  showComparison?: boolean;
}
export interface IDelta extends IStylable {
  value?: number;
  type: string;
  delta: number;
  isEstimate?: boolean;
}
export interface ILineItem extends IStylable {
  title?: string[];
  heading?: string;
  amount: CurrencyOrRate;
  interest?: CurrencyOrRate;
}
export type DebtDetail = {
  issuerName: string;
  program: string;
  lender: string;
  source?: string;
  lenderImage?: {
    size: string;
    url: string;
  };
  lenderDisclaimer?: string;
  lenderDescription?: string;
};
export interface IBestOffer extends IStylable {
  id: string;
  amount: CurrencyOrRate;
  interest: CurrencyOrRate;
  title: string;
  group: string;
  loanGroup: string;
  detail: DebtDetail;
  fees: ILineItem[];
  channelLenderURL: string;
  offerType: 'firm' | string;
  totalSavings: number;
  payments: ILineItem[];
  scheduledMonthlyPayment: number;
  estimatedMonthlyPayment: number;
  newDebtCost: number;
  currentDebtCost: number;
  expectedPayoff?: string;
  strategy?: any;
}
export type ServerLoanType = {
  type: string;
  group: string;
  principalBalance: number;
  interestRate: number;
  remainingTermMonths: number;
  estimatedMonthlyPayment: number;
};
export type ServerRecommendationType = {
  issuerName: string;
  group: string;
  product: number;
  pointsAndCreditFees: {
    type: string;
    amount: number;
  };
  estimatedMonthlyPayment: number;
  scheduledMonthlyPayment: number;
  id: string;
};
export enum HousingType {
  Rent = 'Rent',
  Own = 'Own',
}
