import * as React from 'react';
import styled from 'styled-components';

// Material UI
import FacebookIcon from '@mui/icons-material/Facebook';
// import AppleIcon from '@mui/icons-material/Apple';
import MailIcon from '@mui/icons-material/Mail';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';

import { ZeroStylesButton } from './ZeroStylesButton';
import { IMAGES, getImage, handleTap } from '../utils';

const GoogleIcon = getImage(IMAGES.GOOGLE_ICON.ref);
const AppleIcon = getImage(IMAGES.APPLE_BUTTON.ref);

const Button = styled(ZeroStylesButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Majorant-Medium';
  font-size: 16px;
  border: 1px solid #3d79dd;
  border-radius: 8px;
  color: #fff;
  padding: 0 8px;
  height: 42px;
  cursor: pointer;
  width: 100%;
  color: #3d79dd;
  &:hover,
  &:focus {
    background-color: #3d79dd;
    color: #fff;

    & img,
    svg {
      color: #fff !important;
    }
  }
  & img,
  svg {
    color: #fff;
    border: none;
    width: 60%;
    position: absolute;
    left: 1px;
    width: 46px;
  }
`;

export const GoogleButton = ({
  onClick,
  isSignIn = true,
}: {
  onClick: () => void;
  isSignIn: boolean;
}) => (
  <Button
    onClick={onClick}
    type="button"
    style={{ backgroundColor: '#000', color: '#fff', border: '1px solid' }}
  >
    <img
      src={GoogleIcon}
      style={{ height: '100%', width: 'auto', margin: '2px 4px' }}
    />
    <span>Sign {isSignIn ? 'in' : 'up'} with Google</span>
  </Button>
);

const iconStyle = {
  width: '26px',
  height: '26px',
  padding: '6px 6px',
  margin: '2px 4px',
  color: '#1977f2',
};

export const AppleButton = ({
  onClick,
  isSignIn = true,
}: {
  onClick: () => void;
  isSignIn: boolean;
}) => (
  <Button
    onClick={onClick}
    type="button"
    style={{ backgroundColor: '#000', color: '#fff', border: '1px solid' }}
  >
    <img
      src={AppleIcon}
      style={{ height: '100%', width: 'auto', margin: '2px 4px' }}
    />
    <span>Sign {isSignIn ? 'in' : 'up'} with Apple</span>
  </Button>
);

export const FacebookButton = ({
  onClick,
  isSignIn = true,
}: {
  onClick: () => void;
  isSignIn: boolean;
}) => (
  <Button onClick={onClick} type="button">
    <FacebookIcon style={iconStyle} />
    <span>Sign {isSignIn ? 'in' : 'up'} with Facebook</span>
  </Button>
);

export const PhoneButton = ({
  onClick,
  isSignIn = true,
}: {
  onClick: () => void;
  isSignIn: boolean;
}) => (
  <Button onClick={onClick} type="button">
    <PhoneIcon style={iconStyle} />
    <span>Sign {isSignIn ? 'in' : 'up'} with Phone</span>
  </Button>
);

export const EmailButton = ({
  onClick,
  isSignIn = true,
}: {
  onClick: () => void;
  isSignIn: boolean;
}) => (
  <Button onClick={onClick} type="button">
    <MailIcon style={iconStyle} />
    <span>Sign {isSignIn ? 'in' : 'up'} with Email</span>
  </Button>
);

export const MagicLinkButton = ({ onClick }: { onClick: () => any }) => (
  <Button onClick={onClick} type="button">
    <LinkIcon style={iconStyle} />
    <span>Continue with a One-Time Link</span>
  </Button>
);
