import { getApp, initializeApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  setPersistence,
} from 'firebase/auth';
import {
  AuthStateChange,
  FirebaseAuthentication,
  Persistence,
} from '@capacitor-firebase/authentication';

// Services
import { recordEvent, updateIdentity } from './user.service';
import { AUTH_ERROR_CODES } from '../providers/auth.provider';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getViewportType } from '../hooks/useWindowSize';
import { getUtmParams } from '../analytics/segment';

const app = initializeApp({
  apiKey:
    process.env.FIREBASE_API_KEY || 'AIzaSyB6FVqG03uPrGR3nD79Rp22kxCCCiqm3ys', // This fallback apiKey is OK to be in plain text
  authDomain:
    process.env.FIREBASE_AUTH_DOMAIN || 'solve-mdop-staging.firebaseapp.com',
  projectId: process.env.FIREBASE_PROJECT_ID || 'solve-mdop-staging',
  messagingSenderId: process.env.FIREBASE_M_S_ID || '129832983205',
  appId:
    process.env.FIREBASE_APP_ID || '1:129832983205:web:2e61ccca7d32db5c55cb4c',
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
});

const UTM_PROP_MAP = {
  utmSource: 'source',
  utmMedium: 'medium',
  utmCampaign: 'campaign',
};

getUtmParams().then((params) => {
  for (let i = 0; i < Object.keys(params).length; i++) {
    var key = Object.keys(params)[i];

    if (params[key] !== null && UTM_PROP_MAP[key]) {
      setFirebaseAnalyticsUserProperty(UTM_PROP_MAP[key], params[key]);
    }
  }
});

let currAuth;

export const getFirebaseAuth = () => {
  if (currAuth) return currAuth;

  if (Capacitor.isNativePlatform()) {
    currAuth = initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    currAuth = getAuth();

    setPersistence(currAuth, indexedDBLocalPersistence);
  }

  return currAuth;
};

const startEmulators = async () => {
  console.debug('Connecting to Firebase emulators...');

  await FirebaseAuthentication.useEmulator({
    host: process.env.FIREBASE_AUTH_EMULATOR_HOST,
    port: Number(process.env.FIREBASE_AUTH_EMULATOR_PORT || 9099),
  });

  console.debug(
    'Connecting firestore',
    process.env.FIREBASE_STORE_EMULATOR_HOST,
    Number(process.env.FIREBASE_STORE_EMULATOR_PORT || 9001)
  );
  connectFirestoreEmulator(
    getFirestore(),
    process.env.FIREBASE_STORE_EMULATOR_HOST,
    Number(process.env.FIREBASE_STORE_EMULATOR_PORT || 9001)
  );
};

export const startListeners = async ({
  setFirebaseUser,
  setLoading,
  setError,
  processEventQueue,
  setRequestOTP,
  setInitialized,
  setInvisibleCaptcha,
  destroyRecaptcha,
}) => {
  await FirebaseAuthentication.removeAllListeners();

  try {
    await FirebaseAuthentication.addListener(
      'authStateChange',
      (change: AuthStateChange) => {
        if (setFirebaseUser) setFirebaseUser(change.user);
        if (setLoading) setLoading(false);

        let platform = 'unknown';
        try {
          platform = Capacitor.getPlatform();
        } catch {}

        if (change?.user) {
          setFirebaseAnalyticsUserId(change.user.uid);
          processEventQueue(change.user);
          updateIdentity({
            platform,
            viewport: getViewportType(),
          });
          console.log("Initializing Auth");
          setInitialized(true);
        }

        console.log('Auth State Change', change);
      }
    );

    await FirebaseAuthentication.addListener(
      'phoneVerificationCompleted',
      (phoneVerification) => {
        setLoading(false);
        setFirebaseUser(phoneVerification.user);

        recordEvent({
          eventName: 'Sign Up',
          firebaseUser: phoneVerification.user,
          props: { type: 'PHONE' },
        });
      }
    );

    await FirebaseAuthentication.addListener('phoneCodeSent', (event) => {
      window.verificationId = event.verificationId;
      setLoading(false);
      setRequestOTP(true);
    });

    await FirebaseAuthentication.addListener(
      'phoneVerificationFailed',
      (err) => {
        setInvisibleCaptcha(false);
        destroyRecaptcha();
        setLoading(false);
        setError(AUTH_ERROR_CODES.PHONE_VERIFICATION_FAILED);
      }
    );
  } catch (e) {
    console.error('Error starting Firebase listeners', e);
  }
};

export const initializeFirebase = async ({
  setFirebaseUser,
  setLoading,
  setError,
  processEventQueue,
  setRequestOTP,
  setInitialized,
  setInvisibleCaptcha,
  destroyRecaptcha,
}) => {
  if (process.env.USE_EMULATOR) {
    await startEmulators();
  }

  const auth = getFirebaseAuth();

  FirebaseAuthentication.setPersistence({
    persistence: Persistence.IndexedDbLocal,
  });

  console.log(
    'Firebase Auth Status',
    { ...auth },
    auth,
    FirebaseAuthentication,
    app
  );

  await startListeners({
    setFirebaseUser,
    setLoading,
    setError,
    processEventQueue,
    setRequestOTP,
    setInitialized,
    setInvisibleCaptcha,
    destroyRecaptcha,
  });
};

export const setFirebaseAnalyticsUserId = async (userId) => {
  await FirebaseAnalytics.setUserId({
    userId,
  });
};

export const setFirebaseAnalyticsUserProperty = async (key, value) => {
  await FirebaseAnalytics.setUserProperty({
    key,
    value,
  });
};

export const trackFirebaseEvent = async (name, params) => {
  await FirebaseAnalytics.logEvent({
    name,
    params,
  });
};
