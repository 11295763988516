import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

// Material UI
import Box from '@mui/material/Box';

import { ButtonLink } from '../page-components-copy/components';
import { ROUTE_PATHS } from '../_pages/_utils/router.utils';
import { useAuthContext } from '../../providers/auth.provider';
import { theme } from '../styles/GlobalStyle';
import { IMAGES, getImage, handleTap } from '../../utils';

const GraySquares = getImage(IMAGES.GRAY_SQUARES.ref);
const screenshot = getImage(IMAGES.SCREENSHOT.ref);
const Homes = getImage(IMAGES.HOUSE_TAG.ref);

const ImageHolder = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 1;
  width: calc(100% - 48px);
  margin: 48px 24px 0px;
`;

const MobileHome: React.FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();
  const { handleSignUp } = useAuthContext();

  // State
  const steps = 3;
  const [currStep, setStep] = useState(0);
  const isLastStep = currStep === steps - 1;

  const onSkip = async () => {
    navigate(ROUTE_PATHS.AUTHENTICATE);
  };

  const onSignUp = async () => {
    handleSignUp();
    navigate(ROUTE_PATHS.ONBOARDING);
  };

  const nextStep = () => setStep(Math.min(currStep + 1, steps - 1));
  const prevStep = () => setStep(Math.max(currStep - 1, 0));

  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextStep,
    onSwipedRight: prevStep,
    trackMouse: true,
  });

  return (
    <div className={className} {...swipeHandlers}>
      <Box flexGrow={1} padding="16px" display="flex" alignItems="center">
        {currStep === 0 && (
          <Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
            <ImageHolder style={{ backgroundImage: `url("${screenshot}")` }} />
            <h1>Fix Your Credit</h1>
            <p style={{ marginBottom: 0 }}>
              Improve your credit with our affordable, unbiased assistance
            </p>
          </Box>
        )}
        {currStep === 1 && (
          <Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
            <ImageHolder style={{ backgroundImage: `url("${Homes}")` }} />
            <h1>Improve Your Homebuying Power</h1>
            <p>
              We unlock potential improvements that give our users &gt; 25%
              higher home affordability on average
            </p>
          </Box>
        )}
        {currStep === 2 && (
          <Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
            <ImageHolder style={{ backgroundImage: `url("${GraySquares}")` }} />
            <h1>Save Money and Lower Your Monthly Debt Payments</h1>
            <p>
              Effortlessly navigate to lower cost borrowing. We've identified
              over $1,000/year savings on average
            </p>
          </Box>
        )}
      </Box>
      <Box textAlign="center">
        {[...Array(steps)].map((_, index) => (
          <span
            key={index}
            style={{
              display: 'inline-block',
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: index === currStep ? '#007bff' : '#ccc',
              margin: '0 4px',
              transition: 'background-color 0.3s ease',
            }}
          />
        ))}
      </Box>
      <Box width="100%" bgcolor={theme.blue4}>
        <Box bgcolor={theme.white} borderRadius="0 0 20px 20px" height="32px" />
      </Box>
      {!isLastStep && (
        <Box
          bgcolor={theme.blue4}
          display="flex"
          padding="16px"
          width="100%"
          boxSizing="border-box"
          className="safe-bottom"
        >
          <ButtonLink
            color={theme.white}
            bgColor={theme.blue4}
            onClick={onSkip}
          >
            Skip
          </ButtonLink>
          <Box flexGrow={1} />
          <ButtonLink
            color={theme.white}
            bgColor={theme.blue4}
            onClick={nextStep}
          >
            Next
          </ButtonLink>
        </Box>
      )}
      {isLastStep && (
        <Box
          bgcolor={theme.blue4}
          width="100%"
          justifyContent="center"
          alignContent="center"
          display="flex"
          padding="16px"
          className="safe-bottom"
          boxSizing="border-box"
        >
          <ButtonLink
            color={theme.white}
            bgColor={theme.blue4}
            style={{ width: '120px' }}
            onClick={() => navigate(ROUTE_PATHS.LOGIN)}
          >
            Sign In
          </ButtonLink>
          <Box flexGrow={1} />
          <ButtonLink style={{ width: '120px' }} onClick={onSignUp}>
            Get Started
          </ButtonLink>
        </Box>
      )}
    </div>
  );
};

export const MobileHomeStyled = styled(MobileHome)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  overflow-x: visible;
  flex-grow: 1;

  & h1 {
    text-align: center;
    margin-bottom: 0;
  }

  & p {
    text-align: center;
    line-height: 1.75em;
  }

  & button {
    height: 48px;
    min-width: 100px;
  }

  & .safe-bottom {
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }
`;

export default MobileHomeStyled;
