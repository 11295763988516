import * as React from 'react';
import styled from 'styled-components';

import { IMAGES, getImage } from '../../utils';

const Quotes = getImage(IMAGES.QUOTES.ref);

const Container = styled('div')<{ fullWidth?: boolean }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ theme }) => theme.gray1};
  color: ${({ theme }) => theme.blue7};
  ${({ fullWidth }) => (!fullWidth ? 'max-width: 700px' : '')};
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding: ${({ fullWidth }) => (fullWidth ? '50px 12px' : '50px 70px')};
  @media screen and${({ theme }) => theme.mq.desktopLargeMin} {
    padding: ${({ fullWidth }) => (fullWidth ? '50px 0' : '50px 100px')};
    font-size: 18px;
  }
  & .italic {
    display: block;
    margin-top: 10px;
    font-style: italic;
  }
`;

const StyledQuotes = styled('img')<{ last?: boolean }>`
  position: absolute;
  ${({ last }) =>
    last
      ? `
bottom: -10px;
right: 20px;
transform: rotate(180deg);
`
      : `
top: -10px;
left: 20px;
`}
`;

export const QuoteBlock = ({
  quote,
  fullWidth,
}: {
  quote: JSX.Element;
  fullWidth?: boolean;
}) => {
  return (
    <Container fullWidth={fullWidth}>
      {quote}
      <StyledQuotes src={Quotes} alt="quotation mark" />
      <StyledQuotes src={Quotes} last={true} alt="quotation mark" />
    </Container>
  );
};
