import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Capacitor } from '@capacitor/core';

// Utils
import { DEBT_GOAL } from '../../../components/Optimizer/types';
import { usePageChange } from '../../../hooks/usePageChange';
import {
  useOnboardingContext,
  useOnboardingDispatch,
} from '../../../providers/onboarding/onboarding.provider';
import {
  setSource,
  setGoal,
} from '../../../providers/onboarding/onboarding.actions';
import AppUrlListener from '../../../utils/deeplink.utils';

export const WrapperLayout = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  usePageChange();

  const source = params.get('utm_source');
  const maximizeHome = params.get('maximizeHome') || params.get('homeMaximize');
  const campaign = params.get('utm_campaign');
  const medium = params.get('utm_medium');

  const state = useOnboardingContext();
  const dispatch = useOnboardingDispatch();

  useEffect(() => {
    if (source) {
      localStorage.setItem('solve_source', source);
      localStorage.setItem('utm_source', source);
    }

    if (campaign) {
      localStorage.setItem('utm_campaign', campaign);
    }

    if (medium) {
      localStorage.setItem('utm_medium', medium);
    }

    if (!localStorage.getItem('referrer')) {
      localStorage.setItem('referrer', document.referrer);
    }

    const activeSource = localStorage.getItem('solve_source');
    if (activeSource && state.source !== activeSource) {
      dispatch(setSource(activeSource));
    }
  }, [source, campaign, medium, document.referrer]);

  useEffect(() => {
    if (maximizeHome === 'true') {
      localStorage.setItem('solve_goal', DEBT_GOAL.MAX_HOME);
      dispatch(setGoal({ goal: DEBT_GOAL.MAX_HOME }));
    }
  }, [maximizeHome]);

  useEffect(() => {
    if (campaign === 'cash') {
      localStorage.setItem('solve_goal', DEBT_GOAL.GET_CASH);
      dispatch(setGoal({ goal: DEBT_GOAL.GET_CASH }));
    }
  }, [campaign]);

  return (
    <>
      {Capacitor.isNativePlatform() && <AppUrlListener />}
      <Outlet />
    </>
  );
};
