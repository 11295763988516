import React, { useContext, useEffect, useReducer } from 'react';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

// Services
import { useAuthContext } from '../auth.provider';
import { getUser } from '../../services/user.service';
import { initialState, userReducer } from './user.reducer';
import { isInitialized, setLoading, setUser } from './user.actions';
import { identifyUser } from './user.utils';
import { captureException } from '../../analytics/sentry';
import { Capacitor } from '@capacitor/core';

const UserContext = React.createContext(null);
const UserDispatchContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const {
    firebaseUser,
    initialized: authInitialized,
    loading: authLoading,
  } = useAuthContext();
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    if (authInitialized && !authLoading) {
      if (!state.user && firebaseUser && !firebaseUser?.isAnonymous) {
        dispatch(setLoading(true));

        const fetchUserData = async () => {
          try {
            let user = await getUser(
              firebaseUser.uid,
              (
                await FirebaseAuthentication.getIdToken()
              ).token
            );

            if (user) {
              identifyUser(firebaseUser, user);
              dispatch(setUser(user));
            }
          } catch (e) {
            captureException(e);
          }

          console.debug('UserProvider - Initialized');
          dispatch(isInitialized());
          dispatch(setLoading(false));
        };

        fetchUserData();
      } else if (!state.initialized) {
        console.debug('UserProvider - Initialized');
        dispatch(isInitialized());
      }
    }
  }, [state.initialized, firebaseUser, authInitialized, authLoading]);

  console.debug('UserProvider Context: ', state, firebaseUser, authInitialized);

  return (
    <UserContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

export const useUserDispatch = () => {
  return useContext(UserDispatchContext);
};
