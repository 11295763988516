import React from 'react';
import { Outlet } from 'react-router';

// Material UI
import Box from '@mui/material/Box';

// Utils
import { SnackBarProvider } from '../../../components/Snackbar/useSnackBar';

// Components
import Header from '../../Layout/Navigation/Header';
import Footer from '../../Layout/Footer';
import { useWindowWidth } from '../../../hooks/useWindowSize';

export const CustomerServiceLayout = () => {
  window.onbeforeunload = null;

  const { isMobile } = useWindowWidth();

  return (
    <>
      <Header disableAccountLinks />
      <SnackBarProvider>
        <Box
          mx={isMobile ? 0 : 4}
          px={isMobile ? 3 : 0}
          style={{ overflowX: 'visible' }}
        >
          <Outlet />
        </Box>
      </SnackBarProvider>
      <Footer />
    </>
  );
};
