/**
 * React and friends
 */
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Material UI components
 */
import MenuIcon from '@mui/icons-material/Menu';
import UserIcon from '@mui/icons-material/PersonOutline';

/**
 * Custom components
 */
import Menu from '../Menu';
import SharedHeader from './SharedNavigation/SharedHeader';
import NavItems from './NavItems';

// Services
import { useUserDispatch } from '../../../providers/user/user.provider';
import { useOnboardingDispatch } from '../../../providers/onboarding/onboarding.provider';
import { resetState as resetOnboardingState } from '../../../providers/onboarding/onboarding.actions';
import { resetState as resetUserState } from '../../../providers/user/user.actions';
import { useAuthContext } from '../../../providers/auth.provider';

/**
 * Auto converted to Component by parcel
 */
import { useWindowWidth } from '../../../hooks/useWindowSize';
import { ZeroStylesButton } from '../../ZeroStylesButton';
import { useScrollPosition } from '../../../hooks/useScrollPosition';
import MobileHeader from './SharedNavigation/MobileHeader';

// Data
import {
  BASE_ROUTE_PATHS,
  ROUTE_PATHS,
} from '../../../components/_pages/_utils/router.utils';
import { IMAGES, getImage, handleTap } from '../../../utils';
import BackButton from '../../BackButton';

const solveFinanceLogo = getImage(IMAGES.SOLVE_LOGO_BLACK_TEXT.ref);
/**
 * Compile time type checking
 */
type Close = {
  display: string;
};

const Nav = styled('nav')<{ scrolled: boolean; close?: Close }>`
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
  padding-top: env(safe-area-inset-top);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  z-index: 10;

  @media ${({ theme }) => theme.mq.desktopMin} {
    height: 80px;
  }
  & .container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    ${({ scrolled }) =>
      scrolled ? 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);' : ''};
    @media ${({ theme }) => theme.mq.desktopMin} {
      height: 90px;
      margin: auto;
    }

    @media ${({ theme }) => theme.mq.tabletMax} {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
  & .full-width {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin: 0;
    @media ${({ theme }) => theme.mq.desktopMin} {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  & svg.main,
  img.main {
    cursor: pointer;
    width: auto;
    height: 31px;
    @media ${({ theme }) => theme.mq.desktopMin} {
      height: 40px;
    }
  }
  & svg.mobile,
  img.mobile {
    cursor: pointer;
    width: auto;
    height: 16px;
    @media ${({ theme }) => theme.mq.desktopMin} {
      height: 40px;
    }
  }
  & .user-box {
    cursor: pointer;
    & > svg {
      height: 24px;
    }
  }
  & > div {
    background-color: ${({ theme }) => theme.white};
    z-index: 3;
    width: 100vw;
  }
  & > div > div > div {
    margin: 0;
    display: flex;
    flex-direction: row;
    & .MuiSvgIcon-root {
      margin-right: 21px;
      display: ${({ close }) => close?.display};
    }
  }

  & .MuiSvgIcon-root {
    color: black;
    font-size: 28px;
  }
`;

/**
 * Component for the Header of pages
 */
const Header = ({ disableAccountLinks }: { disableAccountLinks?: boolean }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onboardingDispatch = useOnboardingDispatch();
  const userDispatch = useUserDispatch();
  const { firebaseUser, handleSignOut } = useAuthContext();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen((prev) => !prev);

  // Close drawer when route changes
  React.useEffect(() => {
    if (isDrawerOpen) {
      toggleDrawer();
    }
  }, [pathname]);

  const { isMobile } = useWindowWidth();
  const { y: scrollY } = useScrollPosition();

  const inApp = pathname.startsWith(BASE_ROUTE_PATHS.DASHBOARD) && firebaseUser;

  const onAbort = async () => {
    onboardingDispatch(resetOnboardingState());
    userDispatch(resetUserState());
    navigate(ROUTE_PATHS.AUTHENTICATE);

    await handleSignOut();
  };

  const [isMenuClickable, setIsMenuClickable] = useState(true);
  React.useEffect(() => {
    setIsMenuClickable(false);
    const timeoutId = setTimeout(() => {
      setIsMenuClickable(true);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [pathname]);

  return inApp ? (
    <Nav scrolled={scrollY > 0}>
      <div className="container">
        <div className="full-width">
          <div>
            {isMobile &&
              (pathname.startsWith('/dashboard/') ? (
                <BackButton />
              ) : (
                <MenuIcon
                  onClick={isMenuClickable ? toggleDrawer : undefined}
                />
              ))}
            <ZeroStylesButton
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => navigate(ROUTE_PATHS.HOME)}
            >
              <img src={solveFinanceLogo} className="main" />
            </ZeroStylesButton>
            {!isMobile && <NavItems />}
          </div>
          <ZeroStylesButton className="user-box" data-testid="account-btn">
            <UserIcon onClick={() => navigate(ROUTE_PATHS.MY_ACCOUNT)} />
          </ZeroStylesButton>
          <Menu isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
            {
              <ul>
                <li>
                  <Link to={ROUTE_PATHS.DASHBOARD}>Dashboard</Link>
                </li>
                <li>
                  <Link to={ROUTE_PATHS.MY_DEBTS}>Debts</Link>
                </li>
                <li>
                  <Link to={ROUTE_PATHS.CREDIT_SCORE}>Credit Score</Link>
                </li>
                <li>
                  <Link to={ROUTE_PATHS.MY_ACCOUNT}>Your account</Link>
                </li>
              </ul>
            }
          </Menu>
        </div>
      </div>
    </Nav>
  ) : (
    <>
      {!isMobile ? (
        <>
          <SharedHeader
            disableAccountLinks={disableAccountLinks}
            currentPage={pathname}
            handleSignOut={onAbort}
          />
        </>
      ) : (
        <>
          <MobileHeader
            disableAccountLinks={disableAccountLinks}
            currentPage={pathname}
            disableCloseButton={false}
            handleSignOut={onAbort}
          />
        </>
      )}
    </>
  );
};

export default Header;
