import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { theme } from '../styles/GlobalStyle';
import { useWindowWidth } from '../../hooks/useWindowSize';

import { TopOfFold } from '../page-components-copy/top-of-fold';
import { ColorBanner } from '../page-components-copy/color-banner';
import { ImageAndList } from '../page-components-copy/image-and-list';

import {
  Bold,
  ButtonLink,
  Paragraph,
  SectionTitle,
  Spacer,
  UnbiasedResources,
} from '../page-components-copy/components';
import { IconFocusCTA } from '../page-components-copy/icon-focus-cta';
import { ROUTE_PATHS } from '../_pages/_utils/router.utils';
import { useAuthContext } from '../../providers/auth.provider';
import { IMAGES, getImage, handleTap } from '../../utils';

const person1 = getImage(IMAGES.PERSON_1.ref);
const Square1 = getImage(IMAGES.SQUARE_1.ref);
const Square2 = getImage(IMAGES.SQUARE_2.ref);
const Square3 = getImage(IMAGES.SQUARE_3.ref);
const LaptopWithMug = getImage(IMAGES.LAPTOP_BLUE_MUG.ref);
const BalloonDog = getImage(IMAGES.BALLOON_DOG.ref);
const CoffeeAndPencil = getImage(IMAGES.COFFEE_AND_PENCIL.ref);
const HouseTag = getImage(IMAGES.HOUSE_TAG.ref);
const BetterLogo = getImage(IMAGES.BETTER.ref);
const QuonticLogo = getImage(IMAGES.QUONTIC.ref);
const MarbleLogo = getImage(IMAGES.MARBLE.ref);
const RenoFiLogo = getImage(IMAGES.RENOFI.ref);

const StyledImageAndList = styled(ImageAndList)`
  & > div:last-child {
    @media ${({ theme }) => theme.mq.desktopLargeMin} {
      max-width: 640px !important;
      margin-left: 32px !important;
    }
    & p span:last-child {
      font-size: 21px;
    }
  }
`;

const House = styled('img')`
  display: flex;
  z-index: -1;
  max-height: 227px;
  max-width: 210px;
  align-self: center;
  margin: auto;
  @media ${theme.mq.desktopLargeMin} {
    margin: unset;
    max-height: 495px;
    max-width: 535px;
    margin-right: 20px;
  }
`;

const solveDifferenceListItems = [
  {
    icon: null,
    text: (
      <span>
        <Bold>Get simple, unbiased advice.</Bold>
        <br />
        You choose whenever to share your data. We offer a premium service
        without advertising and clear, unbiased information from a team of PhDs
        to help you understand your options and next steps.
      </span>
    ),
  },
  {
    icon: null,
    text: (
      <span>
        <Bold>We take your privacy seriously. (Seriously.)</Bold>
        <br />
        Ever given out your phone or email in exchange for a price quote and
        then get bombarded with robocalls and sales messages? Yea, we don’t do
        that.
      </span>
    ),
  },
  {
    icon: null,
    text: (
      <span>
        <Bold>We make it fast and easy.</Bold>
        <br />
        Our financial AI helps you make better borrowing decisions to save
        money, get more money out, or buy more home.
      </span>
    ),
  },
];

const howItWorksItems = [
  {
    icon: <img src={Square1} />,
    text: (
      <span>
        <Bold>Answer a few questions:</Bold> about your current financial
        situation.
      </span>
    ),
  },
  {
    icon: <img src={Square2} />,
    text: (
      <span>
        <Bold>The Debt Optimizer works its unbiased, tech magic</Bold> after
        getting your credit report and score.
      </span>
    ),
  },
  {
    icon: <img src={Square3} />,
    text: (
      <span>
        <Bold>Evaluate your options</Bold> and the quickest ways to increase
        your homebuying power.
      </span>
    ),
  },
];

export enum PARTNER {
  better = 'better',
  quontic = 'quontic',
  marble = 'marble',
  renofi = 'renofi',
}

const PARTNER_LOGO = {
  [PARTNER.better]: BetterLogo,
  [PARTNER.quontic]: QuonticLogo,
  [PARTNER.marble]: MarbleLogo,
  [PARTNER.renofi]: RenoFiLogo,
};

const PARTNER_NAME = {
  [PARTNER.better]: 'Better',
  [PARTNER.quontic]: 'Quontic',
  [PARTNER.marble]: 'Marble',
  [PARTNER.renofi]: 'RenoFi',
};

const PARTNER_COLOR = {
  [PARTNER.quontic]: '#F2994A',
};

const PARTNER_HEADERS = {
  [PARTNER.quontic]: { default: 'Debt is stressful.' },
  [PARTNER.renofi]: { default: 'Debt is Stressful' },
  [PARTNER.better]: {
    default: (
      <>
        <b>Unlock that door:</b>
        <br />
        <span>Improve your credit and get approved</span>
      </>
    ),
    cash: (
      <>
        <b>Unlock that loan:</b>
        <br />
        <span>Improve your credit and get approved</span>
      </>
    ),
  },
  DEFAULT:
    'Unlock that door: improve your credit and secure the perfect mortgage',
};

const PARTNER_COPY = {
  [PARTNER.quontic]: {
    default:
      'Has your credit or DTI (debt-to-income) prevented you from a home mortgage? Our Debt Optimizer tech gives you unbiased advice on how to fix your credit and improve your DTI so you can get into the house you deserve. On average, people have increased their home-buying power by nearly 30%. Get your personalized results in minutes.',
  },
  [PARTNER.better]: {
    default:
      'Our Debt Optimizer helps you increase your homebuying power in minutes. Improve your credit score and optimize your finances to buy the home of your dreams. On average, Better users experience an increase in home-buying power of nearly 30% and potential savings of over $1,000 per year.',
    cash: "Our Debt Optimizer helps you unlock cash in minutes. We help improve your credit score and finances to get cash at the lowest monthly payments. Whether it's debt consolidation or that home improvement you've been waiting for. On average we help identify savings of over $1,000 a year on average for Better users.",
  },
  [PARTNER.marble]: {
    default:
      'Our Debt Optimizer helps you increase your homebuying power in minutes. Improve your credit score and optimize your finances to buy the home of your dreams. On average, Marble users experience an increase in home-buying power of nearly 30% and potential savings of over $1,000 per year.',
  },
  [PARTNER.renofi]: {
    default:
      'Has your credit or DTI (debt-to-income) prevented you from qualifying for a home improvement loan? Our Debt Optimizer service gives you unbiased advice on how to fix your credit and improve your DTI so you can qualify for a second mortgage like you deserve. On average, we can increase score by 20 points in 2 months and identify over $100 in monthly savings. Get your personalized results in minutes.',
  },
  DEFAULT:
    'Our Debt Optimizer helps you increase your homebuying power in minutes. Improve your credit score and optimize your finances to buy the home of your dreams. On average, users experience an increase in home-buying power of nearly 30% and potential savings of over $1,000 per year.',
};

const PARTNER_CTA = {
  [PARTNER.renofi]: { default: 'Qualify for a Loan Today' },
  [PARTNER.better]: {
    default: 'Increase My Homebuying Power',
    cash: 'Get Cash',
  },
  DEFAULT: 'Increase My Homebuying Power',
};

const Landing: React.FC<{
  partner: PARTNER;
  campaign?: string;
  className?: string;
}> = ({ partner, campaign = '', className }) => {
  const navigate = useNavigate();
  const { handleSignUp } = useAuthContext();
  const { isMobile } = useWindowWidth();
  const logo = PARTNER_LOGO[partner];
  if (!campaign) {
    campaign = 'default';
  }

  const onSignUp = () => {
    handleSignUp();
    navigate(ROUTE_PATHS.ONBOARDING);
  };

  return (
    <div
      className={className}
      style={{ paddingTop: isMobile ? '25px' : '12px', overflowX: 'visible' }}
    >
      <TopOfFold
        alt="A magnifying glass searching for a home"
        partnerLogo={{
          svg: logo,
          alt: `${PARTNER_NAME[partner]} Logo`,
        }}
        copy={{
          h1Copy:
            PARTNER_HEADERS[partner]?.[campaign] || PARTNER_HEADERS.DEFAULT,
          pCopy: PARTNER_COPY[partner]?.[campaign] || PARTNER_COPY.DEFAULT,
        }}
        ctas={[
          {
            bgColor: PARTNER_COLOR[partner] || theme.green4,
            callback: onSignUp,
            cta: PARTNER_CTA[partner]?.[campaign] || PARTNER_CTA.DEFAULT,
            testid: 'saving-button',
          },
        ]}
        ImageOverride={
          isMobile ? (
            <House
              src={HouseTag}
              style={{
                transform: 'rotate(180)',
                position: 'absolute',
                right: '-64px',
                top: '48px',
                opacity: 0.25,
              }}
            />
          ) : (
            <House src={HouseTag} />
          )
        }
        imageLocation={{ desktop: 'left', mobile: 'top' }}
        copyWidth="100%"
      />
      <Spacer />
      <Spacer />
      <ColorBanner
        bannerColor="#F2994A"
        copy={{
          headerCopy: (
            <>
              Lower your DTI <br />
              <span>(debt-to-income ratio)</span>
            </>
          ),
          pCopy:
            'Your DTI limits the mortgage you can take and is the number one reason for mortgage denial. The Debt Optimizer shows strategies to decrease your DTI that you can take action immediately.',
        }}
        imageMeta={{
          src: person1,
          mobilePosition: 'bottom',
          alt: 'A borrower living her stress-free life after Solve Finance fixes credit for her',
        }}
      />
      <Spacer />
      <Spacer />
      <ImageAndList
        title="How it works"
        image={
          <img
            src={LaptopWithMug}
            alt="A computer chugging away to optimize debts and scan all your options for you"
          />
        }
        ctas={[]}
        listItems={howItWorksItems}
      />
      <Spacer />
      {!isMobile && (
        <ButtonLink
          onClick={onSignUp}
          bgColor={theme.green4}
          color={theme.white}
          style={{ minWidth: 227, margin: 'auto' }}
        >
          Get Started
        </ButtonLink>
      )}
      <div>
        <SectionTitle>Get into a mortgage with less stress</SectionTitle>
        <Paragraph>
          Within minutes the Debt Optimizer shows personalized data-driven
          options to lower DTI, increase credit score, and maximize the mortgage
          you qualify for.
        </Paragraph>
      </div>
      <Spacer />
      {!isMobile && (
        <>
          <StyledImageAndList
            title="The Solve Difference"
            image={
              <img
                src={CoffeeAndPencil}
                alt="A coffee cup after you sit back and relax letting Solve do the work on optimizing borrowing, fixing credit, and qualifying you for savings"
              />
            }
            ctas={[
              {
                cta: 'Get started',
                callback: onSignUp,
                color: theme.white,
                bgColor: theme.red4,
              },
            ]}
            listItems={solveDifferenceListItems}
            showGrid
          />
          <Spacer />
        </>
      )}

      <IconFocusCTA
        icon={<img src={BalloonDog} alt="balloon animal dog" />}
        title={{
          text: isMobile ? null : 'Start saving on your debt today',
          size: 'l',
        }}
        paragraph={
          isMobile
            ? null
            : 'Try the Debt Optimizer to explore your best-available options. No strings attached'
        }
        ctas={[
          {
            text: 'Get Started',
            callback: onSignUp,
            bgColor: isMobile ? '#EB5757' : theme.blue4,
            color: theme.white,
          },
        ]}
      />
      <Spacer />
      <UnbiasedResources isMobile={isMobile} />
      <Spacer />
      <Spacer />
    </div>
  );
};

export const PartnerLanding = styled(Landing)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 1200px;
  box-sizing: border-box;
  overflow-x: hidden;
`;

export default PartnerLanding;
